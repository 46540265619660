<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="userLocal.image"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
      <b-form-file
        v-model="profileFile"
        :state="Boolean(profileFile)"
        :placeholder="$t('user_profile.choose_holder')"
        drop-placeholder="Drop file here..."
        accept="image/*"
      ></b-form-file>
      <div class="mt-1"> {{$t('user_profile.selected')}} {{ profileFile ? profileFile.name : '' }}</div>
      <b-card-text>{{$t('user_profile.allowed')}}</b-card-text>
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75 text-capitalize align-self-center"
          @click="uploadImage"
        >
          {{$t('user_profile.upload')}}
        </b-button>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('user_profile.username')" class="text-capitalize" label-for="account-username">
            <b-form-input
              v-model="userLocal.user"
              name="username"
              :placeholder="$t('user_profile.username')"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group :label="$t('user_profile.email')" class="text-capitalize" label-for="account-e-mail">
            <b-form-input
              v-model="userLocal.email"
              name="email"
              :placeholder="$t('user_profile.email')"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group :label="$t('user_profile.name')" class="text-capitalize" label-for="account-name">
            <b-form-input
              v-model="userLocal.name"
              name="name"
             :placeholder="$t('user_profile.name')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group :label="$t('user_profile.first_name')" class="text-capitalize" label-for="account-name">
            <b-form-input
              v-model="userLocal.first_name"
              name="name"
              :placeholder="$t('user_profile.first_name')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group :label="$t('user_profile.second_name')" class="text-capitalize" label-for="account-second-name">
            <b-form-input
              v-model="userLocal.second_name"
              name="second-name"
             :placeholder="$t('user_profile.second_name')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group :label="$t('user_profile.plate_number')" class="text-capitalize" label-for="account-plate-numer">
            <b-form-input
              v-model="userLocal.plate_number"
              name="plate-numer"
             :placeholder="$t('user_profile.plate_number')"
            />
          </b-form-group>
        </b-col>


        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1 text-capitalize"
            v-on:click="changeUserData(userLocal)"
          >
            {{$t('user_profile.save_changes')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2 text-capitalize"
            @click.prevent="resetForm"
          >
            {{$t('user_profile.reset')}}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { email } from "@validations";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      userLocal: JSON.parse(JSON.stringify(this.userData)),
      profileFile: null,
      profileImage: this.userData.image ? `${process.env.VUE_APP_BASE_URL}/v1/users/image?path=${this.userData.image}` : require('@/assets/images/avatar-user.png')
    };
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    uploadImage() {
      const formData = new FormData();
      formData.append('profile_image', this.profileFile)
      store.dispatch("user/saveUserImage", {
          formData,
        })
        .then((response) => {
          if (response.success) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.userLocal.image = response.data
            localStorage.setItem('userData', JSON.stringify(this.userLocal))
          } else {
            this.toast(
              {
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                timeout: 10000,
              }
            );
          }
        });
    },
    changeUserData({
      email,
      name,
      first_name,
      second_name,
      id_profile,
      permissions,
      plate_number
    }) {
      let params = {
        email,
        name,
        first_name,
        second_name,
        id_profile,
        permission: [...permissions],
        plate_number
      };
      store
        .dispatch("user/edit", {
          id: this.userLocal.id,
          params,
        })
        .then((response) => {
          if (response.success) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            localStorage.setItem('userData', JSON.stringify(this.userLocal))
          } else {
            this.toast(
              {
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                timeout: 10000,
              }
            );
          }
        });
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    const toast = useToast();

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      toast,
    };
  },
};
</script>
