<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold text-capitalize">
          {{ $t("user_profile.general") }}</span
        >
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
        :user-data="user"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("user_profile.change_password") }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("user_profile.notifications.title") }}</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>

    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="MailIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("auth_two_factor.title") }}</span>
      </template>
      <account-setting-auth-two-factor />
    </b-tab>

    <b-tab v-if="$can('create_qr_app', 'AccountSettings')">
      <!-- title -->
      <template #title>
        <feather-icon icon="SmartphoneIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("account_settings.mobile_app.title") }}</span>
      </template>
      <account-setting-mobile-app />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";
import AccountSettingAuthTwoFactor from "./AccountSettingAuthTwoFactor.vue";
import AccountSettingMobileApp from './AccountSettingMobileApp.vue'
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingAuthTwoFactor,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    AccountSettingMobileApp,
  },
  data() {
    return {
      options: {},
      user: JSON.parse(localStorage.getItem("userData")),
    };
  },
  beforeCreate() {
    this.$http.get("/account-setting/data").then((res) => {
      this.options = res.data;
    });
    this.user = JSON.parse(JSON.stringify(localStorage.getItem("userData")));
  },
};
</script>
