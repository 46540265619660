<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mt-2">
        {{ $t("user_profile.notifications.title") }}
      </h6>
      <b-col cols="12" class="mt-1 mb-2">
        <b-form-checkbox
          id="accountSwitch4"
          v-model="showAlarmNotifications"
          name="check-button"
          switch
          inline
        >
          <span>{{
            $t("user_profile.notifications.alarm_notifications")
          }}</span>
        </b-form-checkbox>
      </b-col>
      <!--/ application switch -->
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-1">
        <h6 class="section-label mt-2">{{$t("user_profile.notifications.video_alarm_notify")}}</h6>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6" class="mb-2">
        <b-form-checkbox
          id="accountSwitch_alarms"
          v-model="checkAlarms"
          name="check-button_alarms"
          switch
          inline
          :disabled="group"
        >
          <span> {{$t("user_profile.notifications.show_frame_color")}}</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="3" class="mb-2 justify-content-center">
        <b-form-input id="color" v-model="frameColor" trim type="color"  :disabled="group" />
      </b-col>
    </b-row>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mt-2 mr-1 text-capitalize"
      v-on:click="updateFrameColor()"
      v-if="!group"
    >
      {{ $t("user_profile.save_changes") }}
    </b-button>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      alarmNotifications: true,
      checkAlarms:
        store.state.user.user.groups &&
        store.state.user.user.groups.length > 0
          ? Boolean(store.state.user.user.groups[0].check_alarms)
          : Boolean(store.state.user.user.check_alarms),
      frameColor:   //Si hay un grupo pone el color del grupo pero no lo deja editar, si el usuario no tiene el color
        store.state.user.user.groups &&
        store.state.user.user.groups.length > 0
          ? store.state.user.user.groups[0].frame_color
            ? store.state.user.user.groups[0].frame_color
            : "#FF0000"
          : store.state.user.user.frame_color
          ? store.state.user.user.frame_color
          : "#FF0000",
      group:    store.state.user.user.groups &&
        store.state.user.user.groups.length > 0
          ? store.state.user.user.groups[0] : false

    };
  },
  computed: {
    showAlarmNotifications: {
      get() {
        return store.getters["user/getPreferences"].show_alarm_notifications;
      },
      set(value) {
        store.dispatch("user/updateUserConfig", {
          preferences: {
            show_alarm_notifications: value,
          },
        });
      },
    },
  },
  methods: {
    updateFrameColor() {
      let params = { frame_color: this.frameColor, check_alarms: this.checkAlarms };
      store
        .dispatch("user/updateUserFrameColorAndCheckAlarms", {
          params,
        })
        .then((response) => {
          if (response.success) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            this.toast(
              {
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                timeout: 10000,
              }
            );
          }
        });
    },
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  watch: {
    // alarmNotifications(value) {
    //   const data = {
    //     preferences: {
    //       show_alarm_notifications: value,
    //     },
    //   };
    //   store.dispatch("user/updateUserConfig", data);
    // },
  },
};
</script>
