<template>
  <b-card>
    <b-row class="mb-2">
      <b-col md="12">
        <h6 class="section-label mx-1 mt-2">
          {{ $t("auth_two_factor.title") }}
        </h6>
        <div style="width: 900px; text-align: justify">
          <p id="description" class="section-label mx-1 mt-1 mb-2">
            {{ $t("auth_two_factor.description") }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="1">
        <div v-show="!disabledCheckBoxEmail">
          <div @click="disableAndActiveEmail()">
            <b-form-checkbox
              id="typeEmail"
              v-model="typeEmail"
              name="typeEmail"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
              :disabled="typeSms == 1"
            >
            </b-form-checkbox>
          </div>
        </div>
      </b-col>
      <b-col md="2" style="text-align: center">
        <h5 class="mt-1">
          Email
          <feather-icon :icon="verifyIconEmail" size="30" />
        </h5>
      </b-col>
      <b-col md="3" v-if="enabledEmail">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          class="mb-75 mr-75 text-capitalize align-self-center"
          v-show="disabledCheckBoxEmail"
          variant="primary"
          style="margin-top: 13px"
          :disabled="!disabledCheckBoxEmail"
          @click="verifyEmail()"
        >
          {{ $t("auth_two_factor.verify") }}
        </b-button>
      </b-col>
      <b-col md="3" v-else>
        <b-form-group style="margin-top: 13px">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-new-password"
              v-model="codeEmail"
              type="number"
              name="new-password"
              class="text-capitalize"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75 text-capitalize align-self-center"
                style="margin-top: 1px"
                @click="sendVerifyEmail()"
              >
                {{ $t("auth_two_factor.send") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="1">
        <div v-show="!disabledCheckBoxSms">
          <div @click="disableAndActiveSms()">
            <b-form-checkbox
              id="typeSms"
              v-model="typeSms"
              name="typeSms"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
              :disabled="typeEmail == 1"
            >
            </b-form-checkbox>
          </div>
        </div>
      </b-col>
      <b-col md="2" style="text-align: center">
        <h5 class="mt-1">
          Sms
          <feather-icon :icon="verifyIconSms" size="30" />
        </h5>
      </b-col>
      <b-col md="3" v-if="enabledSms">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75 text-capitalize align-self-center"
          v-show="disabledCheckBoxSms"
          style="margin-top: 13px"
          :disabled="!disabledCheckBoxSms"
          @click="verifySms()"
        >
          {{ $t("auth_two_factor.verify") }}
        </b-button>
      </b-col>
      <b-col md="3" v-else>
        <b-form-group style="margin-top: 13px">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-new-password"
              v-model="codeSms"
              type="number"
              name="new-password"
              class="text-capitalize"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75 text-capitalize align-self-center"
                style="margin-top: 1px"
                @click="sendVerifySms()"
              >
                {{ $t("auth_two_factor.send") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75 text-capitalize align-self-center"
          style="margin-top: 13px"
          @click="saveConfig()"
        >
          {{ $t("actions.save") }}
        </b-button>
      </b-col>
      <!--/ application switch -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { email } from "vee-validate/dist/rules";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: "",
      selectedTypeVerify: "",
      verifyIconSms: "",
      verifyIconEmail: "",
      codeEmail: "",
      codeSms: "",
      verify_email: "",
      verify_sms: "",
      disabledCheckBoxEmail: true,
      enabledEmail: true,
      disabledCheckBoxSms: true,
      enabledSms: true,
      typeEmail: "",
      typeSms: "",
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    user() {
      return store.getters["user/getUser"];
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    disableAndActiveSms() {
      if (this.typeEmail == 1) {
        this.typeEmail = 0;
        this.sms;
      }
    },
    sms() {
      this.typeSms = 1;
    },
    disableAndActiveEmail() {
      if (this.typeSms == 1) {
        this.typeSms = 0;
        this.email;
      }
    },
    email() {
      this.typeEmail = 1;
    },
    verifySmsEmail() {
      let data = JSON.parse(localStorage.getItem("userData"));
      data.verify_email == 0
        ? ((this.verify_email = "sin verificar"),
          (this.disabledCheckBoxEmail = true),
          (this.verifyIconEmail = "XCircleIcon"))
        : ((this.verify_email = "verificado"),
          (this.disabledCheckBoxEmail = false),
          (this.verifyIconEmail = "CheckCircleIcon"));

      data.verify_sms == 0
        ? ((this.verify_sms = "sin verificar"),
          (this.disabledCheckBoxSms = true),
          (this.verifyIconSms = "XCircleIcon"))
        : ((this.verify_sms = "verificado"),
          (this.disabledCheckBoxSms = false),
          (this.verifyIconSms = "CheckCircleIcon"));

      if (data.auth_two_factor_type == "email") {
        this.typeEmail = 1;
      }

      if (data.auth_two_factor_type == "sms") {
        this.typeSms = 1;
      }
      if (data.auth_two_factor_type == "sms/email") {
        this.typeEmail = 1;
        this.typeSms = 1;
      }
      if (data.auth_two_factor_type == "") {
        this.typeEmail = 0;
        this.typeSms = 0;
      }
    },
    saveConfig() {
      let params;
      if (this.typeEmail == 1 && this.typeSms == 0) {
        params = {
          auth_two_factor_type_email: "email",
          auth_two_factor_type_sms: "",
        };
      }
      if (this.typeEmail == 0 && this.typeSms == 1) {
        params = {
          auth_two_factor_type_email: "",
          auth_two_factor_type_sms: "sms",
        };
      }
      if (this.typeSms == 1 && this.typeEmail == 1) {
        params = {
          auth_two_factor_type_email: "email",
          auth_two_factor_type_sms: "sms",
        };
      }
      if (this.typeSms == 0 && this.typeEmail == 0) {
        params = {
          auth_two_factor_type_email: "",
          auth_two_factor_type_sms: "",
        };
      }

      store
        .dispatch("auth_two_factor/saveconfig", params)
        .then((response) => {
          if (response.message == "Email Guardado") {
            let userLocalStorage = JSON.parse(localStorage.getItem("userData"));
            userLocalStorage.auth_two_factor_type = "email";

            localStorage.setItem("userData", JSON.stringify(userLocalStorage));
          }
          if (response.message == "Sms Guardado") {
            let userLocalStorage = JSON.parse(localStorage.getItem("userData"));
            userLocalStorage.auth_two_factor_type = "sms";
            localStorage.setItem("userData", JSON.stringify(userLocalStorage));
          }
          if (response.message == "Email y Sms Guardado") {
            let userLocalStorage = JSON.parse(localStorage.getItem("userData"));
            userLocalStorage.auth_two_factor_type = "sms/email";
            localStorage.setItem("userData", JSON.stringify(userLocalStorage));
          }
          if (response.message == "Cambios Guardados") {
            let userLocalStorage = JSON.parse(localStorage.getItem("userData"));
            userLocalStorage.auth_two_factor_type = "";
            localStorage.setItem("userData", JSON.stringify(userLocalStorage));
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: this.trans["auth_two_factor.saved_successfully"],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: this.trans["auth_two_factor.an_error_occurred"],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    verifyEmail() {
      if (this.verify_email == "sin verificar") {
        store
          .dispatch("auth_two_factor/verifyEmail")
          .then((response) => {
            this.enabledEmail = false;
            this.toast({
              component: ToastificationContent,
              props: {
                title: this.trans["auth_two_factor.email_sent"],
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: this.trans["auth_two_factor.an_error_occurred"],
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    verifySms() {
      if (this.verify_sms == "sin verificar") {
        store
          .dispatch("auth_two_factor/verifySms")
          .then((response) => {
            this.enabledSms = false;
            this.toast({
              component: ToastificationContent,
              props: {
                title: this.trans["auth_two_factor.sms_sent"],
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: this.trans["auth_two_factor.an_error_occurred"],
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    sendVerifyEmail() {
      let params = {
        codeVerifyEmail: this.codeEmail,
      };
      store
        .dispatch("auth_two_factor/sendVerify", params)
        .then((response) => {
          this.verify_email = "verificado";
          this.disabledCheckBoxEmail = false;
          this.verifyIconEmail = "CheckCircleIcon";
          this.enabledEmail = true;
          let userLocalStorage = JSON.parse(localStorage.getItem("userData"));
          userLocalStorage.verify_email = 1;
          localStorage.setItem("userData", JSON.stringify(userLocalStorage));
          this.toast({
            component: ToastificationContent,
            props: {
              title: this.trans["auth_two_factor.email_verification_successful"],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.toast(
            {
              component: ToastificationContent,
              props: {
                title: this.trans["auth_two_factor.an_error_occurred"],
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        });
    },
    sendVerifySms() {
      let params = {
        codeVerifySms: this.codeSms,
      };
      store
        .dispatch("auth_two_factor/sendVerifySms", params)
        .then((response) => {
          this.verify_sms = "verificado";
          this.disabledCheckBoxSms = false;
          this.verifyIconSms = "CheckCircleIcon";
          this.enabledSms = true;
          let userLocalStorage = JSON.parse(localStorage.getItem("userData"));
          userLocalStorage.verify_sms = 1;
          localStorage.setItem("userData", JSON.stringify(userLocalStorage));
          this.toast({
            component: ToastificationContent,
            props: {
              title: this.trans["auth_two_factor.sms_verification_successful"],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.toast(
            {
              component: ToastificationContent,
              props: {
                title: this.trans["auth_two_factor.an_error_occurred"],
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        });
    },
  },
  created() {
    this.verifySmsEmail();
  },
  setup() {
    const { t } = useI18nUtils();
    const trans = {
      "auth_two_factor.saved_successfully": t("auth_two_factor.saved_successfully"),
      "auth_two_factor.an_error_occurred": t("auth_two_factor.an_error_occurred"),
      "auth_two_factor.sending_mail": t("auth_two_factor.sending_mail"),
      "auth_two_factor.email_sent": t("auth_two_factor.email_sent"),
      "auth_two_factor.sending_sms": t("auth_two_factor.sending_sms"),
      "auth_two_factor.sms_sent": t("auth_two_factor.sms_sent"),
      "auth_two_factor.checking_email": t("auth_two_factor.checking_email"),
      "auth_two_factor.email_verification_successful": t(
        "auth_two_factor.email_verification_successful"
      ),
      "auth_two_factor.checking_sms": t("auth_two_factor.checking_sms"),
      "auth_two_factor.sms_verification_successful": t(
        "auth_two_factor.sms_verification_successful"
      ),
    };
    const toast = useToast();
    return {
      toast,
      trans,
    };
  },
};
</script>
<style scoped>
p#description {
  font-weight: normal;
  line-height: 15px;
  text-transform: math-auto;
}
</style>
